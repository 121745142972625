import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

const FeatureGrid = ({gridItems}) => (
    <div className="columns is-multiline">
        {gridItems.map(item => (
            <div key={item.text} className="column is-4-desktop">
                <div className={'icon-circle-container'}>
                    <div className={'icon-circle '}>
                        <i className={item.icon}></i>
                    </div>
                </div>
                <section className="card no-padding no-background no-shadow">
                    <span className={'card-title is-block has-text-centered'}>{item.title}</span>
                    <div className="has-text-centered">
                        <div
                            style={{
                                width: '240px',
                                display: 'inline-block',
                            }}
                        >
                            {/*<PreviewCompatibleImage imageInfo={item} />*/}
                        </div>
                    </div>
                    <p className={'card-first-para'}>{item.firstPara}</p>
                    <p className={'card-body'}>{item.text}</p>
                </section>
            </div>
        ))}
    </div>
)

FeatureGrid.propTypes = {
    gridItems: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
            text: PropTypes.string,
        })
    ),
}

export default FeatureGrid
