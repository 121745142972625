import React from 'react';
import { compose, withProps } from "recompose"
import {withScriptjs, withGoogleMap, GoogleMap, Marker} from "react-google-maps"
const { MarkerWithLabel } = require("react-google-maps/lib/components/addons/MarkerWithLabel");
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");
export const MyMapComponent = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyD_Cm95LpHIKVF9bzVy7GV0gGffeS3Z9Ps",
        loadingElement: <div style={{ height: `100%` }} />,
        mapElement: <div style={{ height: `100%` }} />,
        center: {lat: 52.244614, lng: 0.407290},
    }),
    withScriptjs,
    withGoogleMap
)((props) =>
    <GoogleMap
        defaultZoom={7}
        defaultCenter={{ lat: 51.939427, lng: -0.918665}}
        streetViewControl={false}
        options={{streetViewControl: false, mapTypeControl: false, fullscreenControl: false}}
    >
        <Marker
          position={{lat: 52.244614, lng: 0.407290}}
          onClick={props.onMarkerClick}
          onMouseOver={props.onMarkerMouseOver}
          onMouseOut={props.onMarkerMouseOut}
        >
        {/*<InfoBox*/}
        {/*  options={{closeBoxURL: ``, enableEventPropagation: true}}*/}
        {/*  visible={true}*/}
        {/*>*/}
        {/*    <div style={{backgroundColor: `grey`, opacity: 0.75, padding: `12px`}}>*/}
        {/*        <div style={{fontSize: `16px`, fontColor: `#08233B`}}>*/}
        {/*            Ligna HQ*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</InfoBox>*/}
        </Marker>
        <Marker
            position={{ lat: 51.455463, lng: -2.588210}}
            onClick={props.onMarkerClick}
            onMouseOver={props.onMarkerMouseOver}
            onMouseOut={props.onMarkerMouseOut}
        />
    </GoogleMap>
);

class MyFancyComponent extends React.PureComponent {
    state = {
        isBoxShown: false,
    }

    componentDidMount() {
        // this.delayedShowBox()
    }

    delayedShowBox = () => {
        setTimeout(() => {
            this.setState({ isBoxShown: false })
        }, 3000)
    }

    handleMarkerMouseOver = () => {
        this.setState({ isBoxShown: true })
        // this.delayedShowBox()
    }

    handleMarkerMouseOut = () => {
        this.setState({ isBoxShown: false })
        // this.delayedShowBox()
    }

    render() {
        return (
            <MyMapComponent
                isBoxShown={this.state.isBoxShown && this.props.hasInfoBox}
                onMarkerMouseOver={this.handleMarkerMouseOver}
                onMarkerMouseOut={this.handleMarkerMouseOut}

                containerElement={<div style={{ height: `${this.props.containerElementHeight}` }} />}
                hasInfoBox={this.props.hasInfoBox}
            />
        )
    }
}

export default MyFancyComponent;
