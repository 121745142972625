import React from 'react'
import PropTypes from 'prop-types'
import {Link, graphql} from 'gatsby'
import * as _ from 'lodash';

import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'
import MyFancyComponent from "../components/GoogleMap";
import ContactForm from "../components/ContactForm";


export const IndexPageTemplate = ({
                                      image,
                                      title,
                                      heading,
                                      subheading,
                                      intro,
                                      services,
                                      coverage,
                                      contact,
                                  }) => (
    <div>
        <section className={'hero is-large'}>
            <div className="hero-body margin-top-0 has-black-overlay"
                 style={{
                     backgroundImage: `url(${
                         !!image.childImageSharp
                             ? image.childImageSharp.fluid.src
                             : image
                         })`,
                     backgroundPosition: `top left`,
                     backgroundAttachment: `fixed`,
                     backgroundSize: 'cover',
                     backgroundRepeat: 'no-repeat'
                 }}>
                <div style={{
                    display: 'flex',
                    height: '200px',
                    lineHeight: '1',
                    justifyContent: 'center',
                    alignItems: 'left',
                    flexDirection: 'column',
                    opacity: '0.99'
                }}>
                    <p className="h1 is-size-4-mobile is-size-1-tablet is-size-1-widescreen"
                       style={{
                           // boxShadow: 'rgb(255, 68, 0) 0.5rem 0px 0px, rgb(255, 68, 0) -0.5rem 0px 0px',
                         // backgroundColor: 'rgb(255, 68, 0)',
                         textAlign: 'center',
                         fontFamily: 'Museo',
                         fontWeight: '500',
                         color: 'white',
                         lineHeight: '1.2',
                         padding: '2rem 2rem 1rem',
                         maxWidth: '96%',
                         width: '65vh',
                         margin: '0 auto',
                         whiteSpace: 'pre-line',
                         background: 'rgba(0,0,0,0.5)',
                         borderRadius: '0.5rem 0.5rem 0 0'
                       }}>
                        {title}
                    </p>
                    <h3 className="is-size-6-mobile is-size-5-tablet is-size-4-widescreen"
                        style={{
                            // boxShadow: 'rgb(255, 68, 0) 0.5rem 0px 0px, rgb(255, 68, 0) -0.5rem 0px 0px',
                            // backgroundColor: 'rgb(255, 68, 0)',
                            textAlign: 'center',
                            color: 'white',
                            lineHeight: '1.2',
                            padding: '0 1rem 2rem',
                            maxWidth: '96%',
                            width: '65vh',
                            margin: '0 auto',
                            letterSpacing: '0.5px',
                            background: 'rgba(0,0,0,0.5)',
                            borderRadius: '0 0 0.5rem 0.5rem'
                        }}>
                        {subheading}
                    </h3>
                </div>
            </div>
        </section>


        <section className="section border-top-primary light-green-bg">
            <div className="columns">
                <div className="column is-10 is-offset-1">
                    <div className="content">
                        <div className="columns">
                            <div className="column is-12">
                                <h3 className="has-text-centered is-uppercase section-title">
                                    / {heading} /
                                </h3>
                            </div>
                        </div>
                        <Features gridItems={intro.blurbs}/>
                    </div>
                </div>
            </div>
        </section>


        <section className="section border-top-primary dark-green-bg">
            <div className="columns">
                <div className="column is-12">
                    <h3 className="has-text-centered is-uppercase section-title">
                        / {services.title} /
                    </h3>
                </div>
            </div>
            <div className="columns">
                <div className="column is-10 is-11-desktop mx-auto">
                    <div className="columns flex-wrap">
                        {_.map(services.services, (service, index) => {
                            let firstPara = !_.isNull(service.tagline) ?
                                <p className={'card-first-para'}>{service.tagline}</p> : '';
                            let classes = index === 0 ? ' is-12 is-one-third-desktop' : ' is-6 is-one-third-desktop';
                            return <div className={'column mb-4' + classes}>
                                <div className={'card no-padding white-bg rounded-corners'}>
                                    <div className={'card-header has-padding primary-bg float-icon'}>
                                        <div className={'icon-circle-container'}>
                                            <div className={'icon-circle '}>
                                                <i className={service.icon}></i>
                                            </div>
                                        </div>
                                        <h3 className="card-title font-white has-text-centered">
                                            {service.title}
                                        </h3>
                                        <p className="card-subtitle has-text-centered">{service.subtitle}</p>
                                    </div>
                                    <div className={'card-body'}>
                                        {firstPara}
                                        <p>{service.text}</p>
                                    </div>
                                    <div className={'card-footer'}>
                                        <Link className={'font-mint'} to={service.link}>More Information</Link>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </section>


        <section className="section border-top-primary light-green-bg">
            <div className="columns is-variable is-4">
                <div className="column is-5 is-offset-1">
                    <p className={'has-text-centered is-uppercase section-title'}>/ {coverage.title} /</p>
                    <p className={'card-body font-dark-green'}>{coverage.text}</p>
                    <div className={'my-3'}>
                        <MyFancyComponent
                            hasInfoBox={false}
                            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                            loadingElement={<div style={{height: `100%`}}/>}
                            containerElementHeight={'525px'}
                            mapElement={<div style={{height: `100%`}}/>}
                        />
                    </div>
                </div>
                <div className="column is-5">
                    <p className={'has-text-centered is-uppercase section-title'}>/ {contact.title} /</p>
                    <p className={'card-body font-dark-green mb-3'}>{contact.text}</p>
                    <ContactForm  name={'contact-homepage'}/>
                </div>
            </div>
        </section>
    </div>
)

IndexPageTemplate.propTypes = {
    // image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    // title: PropTypes.string,
    // heading: PropTypes.string,
    // subheading: PropTypes.string,
    // description: PropTypes.string,
    // intro: PropTypes.shape({
    //     blurbs: PropTypes.array,
    // }),
    // data: PropTypes.object.isRequired,
}

const IndexPage = ({data}) => {
    const {frontmatter} = data.markdownRemark

    return (
        <Layout>
            <IndexPageTemplate
                image={frontmatter.image}
                title={frontmatter.title}
                heading={frontmatter.heading}
                subheading={frontmatter.subheading}
                description={frontmatter.description}
                intro={frontmatter.intro}
                services={frontmatter.services}
                coverage={frontmatter.coverage}
                contact={frontmatter.contact}
            />
        </Layout>
    )
}

IndexPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
}

export default IndexPage

export const pageQuery = graphql`
query IndexPage {
    markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
        frontmatter {
            title
            image {
                childImageSharp {
                    fluid(maxWidth: 2048, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            heading
            subheading
            intro {
                blurbs {
                    title
                    image {
                        childImageSharp {
                            fluid(maxWidth: 240, quality: 64) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    firstPara
                    text
                    icon
                }
                heading
                description
            }
            services {
                title
                services {
                    title
                    icon
                    link
                    subtitle
                    tagline
                    text
                }
            }
            coverage {
                title
                text
            }
            contact {
                title
                text
            }
        }
    }
}
`

